import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getMenu({commit}) {
        commit(types.UPDATE_LOADING_MENU, true);
        axios.get(`/api/menu`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `bearer ${process.env.VUE_APP_API_TOKEN}`
            }
        })
            .then(response => {
                if (response.data.ok) {
                    commit(types.UPDATE_MENU, response.data.menu);
                } else {
                    commit(types.UPDATE_ERROR_MENU, response.data.error);
                }
                commit(types.UPDATE_LOADING_MENU, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_MENU, false));
    }
}