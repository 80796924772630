import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_HOME](state, payload) {
        state.loadingHome = payload;
    },
    [types.UPDATE_ERROR_HOME](state, payload) {
        state.errorHome = payload;
    },
    [types.UPDATE_HOME](state, payload) {
        state.home = payload;
    }
}