import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getFooter({commit}) {
        commit(types.UPDATE_LOADING_FOOTER, true);
        axios.get(`/api/footer`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `bearer ${process.env.VUE_APP_API_TOKEN}`
            }
        })
            .then(response => {
                if (response.data.ok) {
                    commit(types.UPDATE_FOOTER, response.data.footer);
                } else {
                    commit(types.UPDATE_ERROR_FOOTER, response.data.error);
                }
                commit(types.UPDATE_LOADING_FOOTER, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_FOOTER, false));
    }
}