<template>
  <v-app>
    <v-main>
      <MainNavigation/>
      <router-view/>
      <MainFooter/>
    </v-main>
  </v-app>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
  name: 'App',
  components: {
    MainNavigation,
    MainFooter
  },
  methods: {
    async getStrapi() {
      this.$store.dispatch("getNavigation");
      this.$store.dispatch("getFooter");
    }
  },
  created() {
    this.getStrapi();
  }
}
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>