import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_NAVIGATION](state, payload) {
        state.loadingNavigation = payload;
    },
    [types.UPDATE_ERROR_NAVIGATION](state, payload) {
        state.errorNavigation = payload;
    },
    [types.UPDATE_NAVIGATION](state, payload) {
        state.navigation = payload;
    }
}