import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getHome({commit}) {
        commit(types.UPDATE_LOADING_HOME, true);
        axios.get(`/api/home`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `bearer ${process.env.VUE_APP_API_TOKEN}`
            }
        })
            .then(response => {
                if (response.data.ok) {
                    commit(types.UPDATE_HOME, response.data.home);
                } else {
                    commit(types.UPDATE_ERROR_HOME, response.data.error);
                }
                commit(types.UPDATE_LOADING_HOME, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_HOME, false));
    }
}