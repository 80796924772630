import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getRestaurant({commit}) {
        commit(types.UPDATE_LOADING_RESTAURANT, true);
        axios.get(`/api/restaurant`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `bearer ${process.env.VUE_APP_API_TOKEN}`
            }
        })
            .then(response => {
                if (response.data.ok) {
                    commit(types.UPDATE_RESTAURANT, response.data.restaurant);
                } else {
                    commit(types.UPDATE_ERROR_RESTAURANT, response.data.error);
                }
                commit(types.UPDATE_LOADING_RESTAURANT, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_RESTAURANT, false));
    }
}