import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
},
    {
        path: '/angebote',
        name: 'offer',
        component: () =>
            import ( /* webpackChunkName: "offer" */ '../views/OfferView.vue')
    },
    {
        path: '/restaurant',
        name: 'restaurant',
        component: () =>
            import ( /* webpackChunkName: "restaurant" */ '../views/RestaurantView.vue')
    },
    // {
    //     path: '/speisekarte',
    //     name: 'menu',
    //     component: () =>
    //         import ( /* webpackChunkName: "menu" */ '../views/MenuView.vue')
    // },
    {
        path: '/imprint',
        name: 'imprint',
        component: () =>
            import ( /* webpackChunkName: "imprint" */ '../views/ImprintView.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () =>
            import ( /* webpackChunkName: "privacy" */ '../views/PrivacyView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () =>
            import ( /* webpackChunkName: "notFound" */ '../views/NotFoundView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

export default router