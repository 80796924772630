<template>
  <v-container class="my-md-16 py-16">
    <h1 class="text-center">Herzlich Willkommen</h1>
    <h3 class="text-center">Griechische Spezialitäten im Restaurant Achillion in Vorsfelde.</h3>
    <div class="py-10 d-flex justify-center">
      <div class="w-25">
        <v-divider/>
      </div>
    </div>
    <div v-if="home && home.openingHours" class="d-flex flex-column align-center text-center">
      <h4 class="mb-3">Unsere Öffnungszeiten</h4>
      <template v-for="(hour, i) in home.openingHours" :key="i">
        <div :class="{'mb-3': home.openingHours.length - 1 !== i}">
          <p v-if="hour.title" class="h6">{{ hour.title }}</p>
          <p v-if="hour.line1">{{ hour.line1 }}</p>
          <p v-if="hour.line2">{{ hour.line2 }}</p>
        </div>
      </template>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HomeWelcome",
  computed: {
    ...mapGetters([
      'home'
    ])
  }
}
</script>