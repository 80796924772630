import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingProposal: false,
    errorProposal: false,
    proposal: null
}

const proposalModule = {
    state,
    mutations,
    actions,
    getters
}

export default proposalModule;