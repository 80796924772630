<template>
  <div id="navigation">
    <v-container class="h-100 d-flex">
      <v-row>
        <v-col cols="6" md="4">
          <div class="nav-logo h-100 d-flex align-center">
            <router-link v-slot="{ navigate, href }" custom to="/">
              <a :href="href" class="d-flex align-center" @click="navigate">
                <img :src="require('@/assets/logo-brown.svg')" alt="Zur Startseite"/>
              </a>
            </router-link>
          </div>
        </v-col>
        <v-col class="d-none d-md-block" cols="4">
          <ul v-if="items.length !== 0" class="h-100 nav-links d-flex align-center justify-center">
            <li v-for="(item, i) in items" :key="i">
              <a v-if="item.pdf" :href="`./${item.url}`" target="_blank">{{ item.text }}</a>
              <router-link v-else :to="item.url">{{ item.text }}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col class="d-none d-md-flex justify-end" cols="4">
          <div v-if="!loadingNavigation" class="nav-contact d-flex align-center">
            <v-btn
                v-if="navigation && navigation.button && navigation.button.url && navigation.button.text"
                :href="navigation.button.url"
                class="btn-primary"
            >
              {{ navigation.button.text }}
            </v-btn>
          </div>
        </v-col>
        <v-col class="d-flex d-md-none align-center justify-end" cols="6">
          <v-btn
              :icon="drawer ? 'mdi-close' : 'mdi-menu'"
              class="nav-toggle"
              size="x-large"
              variant="text"
              @click.prevent.stop="drawer=!drawer"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer
        v-model="drawer"
        temporary
    >
      <div class="px-5 d-flex justify-center align-center nav-drawer-logo" style="height: 100px">
        <img :src="require('@/assets/logo-brown.svg')" alt="Zur Startseite"/>
      </div>
      <v-divider/>
      <v-list v-if="items.length !== 0" density="compact" nav class="py-6">
        <v-list-item v-for="(item, i) in items" :key="i" class="mb-3 d-flex justify-center">
          <a v-if="item.pdf" :href="`./${item.url}`" target="_blank" class="h6">{{ item.text }}</a>
          <router-link v-else class="h6" :to="item.url">{{ item.text }}</router-link>
        </v-list-item>
        <v-list-item class="mt-8 d-flex justify-center">
          <v-btn
              v-if="navigation && navigation.button && navigation.button.url && navigation.button.text"
              :href="navigation.button.url"
              class="btn-primary"
          >
            {{ navigation.button.text }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'MainNavigation',
  data() {
    return {
      drawer: null,
      logo: '',
      items: [
        {
          text: 'Restaurant',
          url: 'restaurant'
        },
        {
          text: 'Speisekarte',
          url: 'Speisekarte.pdf',
          pdf: true
        },
        {
          text: 'Angebote',
          url: 'angebote'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loadingNavigation',
      'navigation'
    ])
  }
}
</script>