<template>
  <v-dialog v-model="active" persistent>
    <div>
      <v-card width="90vw" max-width="500">
        <v-card-title>
          <h5 class="d-flex align-center">
            <v-icon class="me-2">mdi mdi-cookie</v-icon>
            <span v-if="!details">Wir nutzen Cookies</span>
            <span v-else>Privacy Einstellungen</span>
          </h5>
        </v-card-title>
        <v-card-text>
          <template v-if="!details">
            Wir respektieren Ihre Privatsphäre.<br>
            Für ein optimales Website-Erlebnis nutzen wir Cookies um bestimmte Funktionen anzubieten.
            Ihr Klick auf "Akzeptieren" erlaubt uns diese Datenverarbeitung sowie die Weitergabe an Drittanbieter (auch
            in Drittländern) gemäß unserer Datenschutzerklärung. Cookies lassen sich jederzeit ablehnen oder in den
            Einstellungen anpassen.
          </template>
          <template v-else>
            <v-switch v-model="essential" label="Essentiell" hide-details disabled/>
            <p class="mb-3">
              Diese Technologien sind unbedingt erforderlich oder haben den alleinigen Zweck, die Kernfunktionalität
              der Webseite zu gewährleisten.
            </p>
            <v-switch v-model="functional" label="Funktional" hide-details/>
            <p>
              Diese Technologien ermöglichen es uns, Ihnen je nach Ihrem Profil, maßgeschneiderte Inhalte anzubieten.
              Indem Sie diese Cookies akzeptieren, helfen Sie uns, das Layout und die Funktionen unserer Webseite zu
              verbessern.
            </p>
          </template>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <template v-if="!details">
            <v-btn @click="showDetails" class="mb-2 btn-secondary" block>Details anzeigen</v-btn>
            <v-btn @click="denyAll" class="ms-0 mb-2 btn-primary" block>Alles ablehnen</v-btn>
            <v-btn @click="acceptAll" class="ms-0 w-100 btn-primary" block>Alles akzeptieren</v-btn>
          </template>
          <v-btn v-else @click="applyDetails" class="btn-primary" block>Übernehmen</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      active: false,
      details: false,
      essential: true,
      functional: false
    }
  },
  methods: {
    show() {
      this.details = false;
      this.active = true;
    },
    showDetails() {
      this.details = true;
    },
    acceptAll() {
      localStorage.setItem('gdpr', JSON.stringify({
        essential: true,
        functional: true
      }));
      this.active = false;
      location.reload();
    },
    denyAll() {
      localStorage.setItem('gdpr', JSON.stringify({
        essential: false,
        functional: false
      }));
      this.active = false;
      location.reload();
    },
    applyDetails() {
      localStorage.setItem('gdpr', JSON.stringify({
        essential: true,
        functional: this.functional
      }));
      this.active = false;
      location.reload();
    },
  },
  mounted() {
    if (!localStorage.getItem('gdpr')) {
      this.active = true;
    } else {
      const details = JSON.parse(localStorage.getItem('gdpr'));
      this.functional = details.functional;
    }
  }
}
</script>