<template>
  <div class="w-100" style="height: 500px">
    <iframe
        allowfullscreen=""
        class="h-100" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2432.185874496978!2d10.8389291!3d52.4395476!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb2569d063b865b44!2sAchillion!5e0!3m2!1sde!2sde!4v1657890283777!5m2!1sde!2sde" style="border:0;"
        width="100%"/>
  </div>
</template>

<script>
export default {
  name: "HomeMap"
}
</script>