import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_MENU](state, payload) {
        state.loadingMenu = payload;
    },
    [types.UPDATE_ERROR_MENU](state, payload) {
        state.errorMenu = payload;
    },
    [types.UPDATE_MENU](state, payload) {
        state.menu = payload;
    }
}