<template>
  <div id="home">
    <template v-if="!loadingHome">
      <HomeCarousel/>
      <HomeWelcome/>
      <HomeGallery/>
      <HomeIndented/>
      <HomeMap v-if="displayMap"/>
    </template>
    <v-container v-else class="my-md-16 py-16 d-flex justify-center">
      <v-progress-circular
          color="#573720"
          indeterminate
      />
    </v-container>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import HomeCarousel from "@/components/home/HomeCarousel";
import HomeWelcome from "@/components/home/HomeWelcome";
import HomeGallery from "@/components/home/HomeGallery";
import HomeIndented from "@/components/home/HomeIndented";
import HomeMap from "@/components/home/HomeMap";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeCarousel,
    HomeWelcome,
    HomeGallery,
    HomeIndented,
    HomeMap
  },
  computed: {
    displayMap() {
      if (localStorage.getItem('gdpr')) {
        const details = JSON.parse(localStorage.getItem('gdpr'));
        if (details.functional) return true;
      }
      return false;
    },
    ...mapGetters([
      'loadingHome'
    ])
  },
  methods: {
    async getStrapi() {
      this.$store.dispatch("getHome");
    }
  },
  created() {
    this.getStrapi();
  }
});
</script>
