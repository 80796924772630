import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingFooter: false,
    errorFooter: false,
    footer: null
}

const footerModule = {
    state,
    mutations,
    actions,
    getters
}

export default footerModule;