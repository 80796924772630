<template>
  <v-container v-if="home && home.indentedText && home.indentedImage" class="my-md-16 py-16">
    <v-row class="element-indented d-flex flex-column flex-md-row-reverse">
      <v-col class="indented-img pb-0 pb-md-3" cols="12" md="8">
        <div class="d-flex align-center justify-center">
          <v-img :src="home.indentedImage.src" :alt="home.indentedImage.alt" cover/>
        </div>
      </v-col>
      <v-col class="indented-text my-auto pt-0 pt-md-3" cols="12" md="4">
        <div class="pa-16">
          <h3 class="mb-5 text-center text-md-left">Achillion</h3>
          <p class="mb-4 text-center text-md-left" style="white-space: pre-line">{{ home.indentedText }}</p>
          <p class="text-center text-md-left">
            <router-link to="restaurant">> Mehr dazu</router-link>
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HomeIndented",
  computed: {
    ...mapGetters([
      'home'
    ])
  }
}
</script>