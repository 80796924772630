<template>
  <v-carousel v-if="home && home.carousel" :show-arrows="false" height="500" cycle hide-delimiters>
    <v-carousel-item v-for="(slide, i) in home.carousel" :key="i">
      <v-img :alt="slide.alt" :src="slide.src" :cover="true" eager height="500">
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular
                color="#573720"
                indeterminate
            />
          </div>
        </template>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HomeCarousel",
  computed: {
    ...mapGetters([
      'home'
    ])
  }
}
</script>