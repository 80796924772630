import {createStore} from 'vuex';
import navigationModule from "./modules/navigation";
import footerModule from "./modules/footer";
import homeModule from "./modules/home";
import restaurantModule from "./modules/restaurant";
import menuModule from "./modules/menu";
import proposalModule from "./modules/proposal";

export default createStore({
    modules: {
        navigationModule,
        footerModule,
        homeModule,
        restaurantModule,
        menuModule,
        proposalModule
    }
})
