<template>
  <div id="footer" class="px-0 pt-5">
    <v-container>
      <v-row>
        <v-col class="contact footer-card text-center text-md-left" cols="12" md="3">
          <AchillionLogo class="logo"/>
          <p v-if="footer && footer.information" style="white-space: pre-line">{{ footer.information }}</p>
        </v-col>
        <v-col class="opening-hours footer-card text-center text-md-left" cols="12" md="3">
          <h5 class="mb-3">Öffnungszeiten</h5>
          <template v-if="footer && footer.openingHours">
            <template v-for="(hour, i) in footer.openingHours" :key="i">
              <div :class="{'mb-2': footer.openingHours.length - 1 !== i}">
                <p v-if="hour.title" class="mb-0"><span>{{ hour.title }}</span></p>
                <p v-if="hour.line1" class="mb-0">{{ hour.line1 }}</p>
                <p v-if="hour.line2" class="mb-0">{{ hour.line2 }}</p>
              </div>
            </template>
          </template>
        </v-col>
        <v-col class="contact footer-card text-center text-md-left" cols="12" md="3">
          <h5 class="mb-3">Anfahrt</h5>
          <p v-if="footer && footer.arrival" style="white-space: pre-line">{{ footer.arrival }}</p>
        </v-col>
        <v-col class="contact footer-card text-center text-md-left" cols="12" md="3">
          <h5 class="mb-3">Rechtliches</h5>
          <p>
            <router-link to="/imprint">Impressum</router-link>
            <br>
            <router-link to="/privacy">Datenschutz</router-link>
            <br>
            <a @click.stop.prevent="$refs.CookieConsent.show()" href="">Cookies</a>
          </p>
        </v-col>
        <v-col class="copyright" cols="12">
          <p class="text-center">
            Copyright © {{ year }} Achillion Vorsfelde. Alle Rechte vorbehalten
          </p>
        </v-col>
        <v-col class="credits d-flex justify-center align-center" cols="12" md="12">
          Website by
          <a class="pt-0 d-flex align-center" href="https://mula-webdesign.com/" target="_blank">
            <img
                :src="require('@/assets/mula.svg')"
                alt="Mula Webdesign"
                class="ml-2"
                height="25"/>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <CookieConsent ref="CookieConsent"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AchillionLogo from "@/components/AchillionLogo";
import CookieConsent from "@/components/CookieConsent";

export default {
  name: 'MainFooter',
  components: {
    AchillionLogo,
    CookieConsent
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    ...mapGetters([
      'footer'
    ])
  }
}
</script>