import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_PROPOSAL](state, payload) {
        state.loadingProposal = payload;
    },
    [types.UPDATE_ERROR_PROPOSAL](state, payload) {
        state.errorProposal = payload;
    },
    [types.UPDATE_PROPOSAL](state, payload) {
        state.proposal = payload;
    }
}