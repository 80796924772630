import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_RESTAURANT](state, payload) {
        state.loadingRestaurant = payload;
    },
    [types.UPDATE_ERROR_RESTAURANT](state, payload) {
        state.errorRestaurant = payload;
    },
    [types.UPDATE_RESTAURANT](state, payload) {
        state.restaurant = payload;
    }
}