import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingHome: false,
    errorHome: false,
    home: null
}

const homeModule = {
    state,
    mutations,
    actions,
    getters
}

export default homeModule;