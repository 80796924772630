import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_FOOTER](state, payload) {
        state.loadingFooter = payload;
    },
    [types.UPDATE_ERROR_FOOTER](state, payload) {
        state.errorFooter = payload;
    },
    [types.UPDATE_FOOTER](state, payload) {
        state.footer = payload;
    }
}