import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingMenu: false,
    errorMenu: false,
    menu: null
}

const menuModule = {
    state,
    mutations,
    actions,
    getters
}

export default menuModule;