<template>
  <v-row v-if="home && home.gallery" class="element-gallery" no-gutters>
    <v-col v-for="(image, index) in home.gallery" :key="index" cols="12" :md="columnWidth">
      <v-img
          :aspect-ratio="16/9"
          :src="image.src"
          :alt="image.alt"
          cover
          st
      />
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HomeGallery",
  computed: {
    columnWidth() {
      const length = this.home.gallery.length;
      switch (length) {
        case 1:
          return '12';
        default:
          if (length % 3 === 0) {
            return '4';
          }
          return '6';
      }
    },
    ...mapGetters([
      'home'
    ])
  }
}
</script>